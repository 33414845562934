import React from "react";
import {Box, Typography} from "@material-ui/core";
import {Link} from 'gatsby-theme-material-ui';
import {WelcomeDashboardLayout} from "src/components/layout";

export default function PrivacyStatement() {
  return (
    <WelcomeDashboardLayout titlePrefix="Datenschutz" preferRows>
      <Typography variant="h5" component="h1" gutterBottom>
        Datenschutzerklärung
      </Typography>

      <Typography variant="body1" gutterBottom>
        Ihre Daten gehören nur Ihnen – das ist uns bewusst. Wenn Sie allerdings in Kontakt mit uns treten – etwa durch
        den Besuch dieser Webseite oder die Nutzung unserer Dienstleistungen – kommen wir zwangsläufig auch mit Ihren
        Daten in Berührung. Das bedeutet, wir „verarbeiten“ Ihre personenbezogenen Daten wie beispielsweise Ihre
        IP-Adresse, Ihre E-Mail-Adresse oder Ihren Namen. Dabei sind wir darauf bedacht, dass Sie stets die Kontrolle
        und den Überblick über diese Prozesse behalten. Nachfolgend möchten wir Sie daher so transparent wie möglich
        über den Umgang mit Ihren Daten informieren und Ihnen Optionen aufzeigen, wie Sie darauf Einfluss nehmen
        können.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Wir sind in diesem Fall die SOLE Software GmbH (SOLE) und ihre Mitarbeiter. Sollten Sie weitere Informationen
        benötigen oder etwaige Rückfragen zum Datenschutz haben, zögern Sie nicht, uns auch direkt zu kontaktieren.
        Sie erreichen uns telefonisch (+49 6806 4999535-0), per E-Mail (<Link
        to="mailto:kontakt@solesoftware.de">kontakt@solesoftware.de</Link>) oder per Post (Sommerbergstraße 97, 66346
        Püttlingen).
      </Typography>

      <Typography variant="body1" gutterBottom>
        Die SOLE ist Diensteanbieter nach den Bestimmungen des Telemediengesetzes (TMG) und verantwortliche Stelle im
        Sinne der Datenschutz-Grundverordnung (DS-GVO).
      </Typography>

      <Box my={3}>
        <Typography variant="h6" component="h2" gutterBottom>
          Nutzung unserer Webseite
        </Typography>

        <Typography variant="body1" gutterBottom>
          Bei Aufruf der Webseite erheben und speichern wir Ihre Zugriffsdaten (sog. Server-Logfiles). In der Regel
          werden dabei Datum und Uhrzeit der Nutzung, sowie die IP-Adresse des Nutzers zur Abwehr des Zugriffs
          unberechtigter Personen erhoben, drei Tage lang gespeichert und anschließend unwiderruflich gelöscht, sofern
          sie nicht im konkreten Einzelfall zu diesem Zweck weiter erforderlich sind. Zur Fehlerbehandlung werden
          Protokolle über durchgeführte Aktionen der Nutzung ohne Personenbezug erfasst.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Für den Betrieb dieser Webseite nehmen wir die Dienste der Microsoft Cloud Deutschland in Anspruch, wobei
          die
          Daten, die auf unserer Webseite erhoben werden, in unserem Auftrag durch die Microsoft Corporation
          verarbeitet
          werden. Zwischengeschaltet ist dabei allerdings die T-Systems International GmbH, eine Tochtergesellschaft
          der
          Deutschen Telekom, in der Rolle des Datentreuhänders. Der Datentreuhänder kontrolliert dabei jeglichen
          physischen und logischen Zugriff auf Kundendaten, führt Aufgaben, die Zugriff auf Server mit Kundendaten
          erfordern, selbst durch oder beaufsichtigt die Ausführung und überwacht und prüft jeden im Einzelfall
          gewährten Zugriff durch Microsoft und beendet den Zugriff, wenn die jeweilige Aufgabe erfüllt ist. Eine
          Nutzung für eigene kommerzielle Zwecke oder eine Datenweitergabe durch T-Systems außerhalb gesetzlicher
          Verpflichtungen erfolgen dabei jedoch nicht. Die Microsoft Corp. hat grundsätzlich keinen Zugriff auf
          Kundendaten und auch keine technische Möglichkeit, auf Kundendaten ohne Zustimmung des Datentreuhänders oder
          des Kunden gemäß den Zugangskontrollrichtlinien zuzugreifen.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Die Microsoft Corporation hat ihren Sitz in One Microsoft Way, Redmond, WA 98052-7329, USA.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Die T-Systems International GmbH hat ihren Sitz in Hahnstraße 43d, 60528 Frankfurt a.M., Deutschland.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Weitere Informationen finden Sie auch unter <Link
          to="https://www.microsoft.com/de-de/cloud/rechtliche-aspekte.aspx">https://www.microsoft.com/de-de/cloud/rechtliche-aspekte.aspx</Link>.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" component="h2" gutterBottom>
          Teilnahme-Dashboard
        </Typography>

        <Typography variant="body1" gutterBottom>
          Wenn Sie einen Zugangscode von uns erhalten haben, können Sie sich in ein Dashboard einloggen, um Ihre
          Teilnahme am Projekt Sendemeldung.de zu verwalten und persönliche Dokumente abzurufen.
          Bei Nutzung der im Dashboard bereitgestellten Formulare etwa zur Abfrage weiterer und Korrektur bestehender
          persönlicher Daten (z. B. Ihrer E-Mail-Adresse) sowie Ihrer Teilnahmeentscheidung speichern und verwenden wir
          die von Ihnen angegebenen Daten und Kontaktinformationen solange und soweit dies für die Projektfinanzierung
          und -umsetzung sowie &ndash; im Falle Ihrer Teilnahme &ndash; für die anschließende Diensterbringung
          erforderlich ist.
          Wir weisen darauf hin, dass die Kommunikation per E-Mail in der Regel unverschlüsselt erfolgt und daher
          Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte kann somit von uns nicht garantiert werden. Im
          Übrigen gelten hier auch die Datenschutzbestimmungen Ihres Providers.
        </Typography>
      </Box>

      {/*<Box my={3}>*/}
      {/*  <Typography variant="h6" component="h2" gutterBottom>*/}
      {/*    Newsletter*/}
      {/*  </Typography>*/}

      {/*  <Typography variant="body1" gutterBottom>*/}
      {/*    Um über den Projektfortschritt zu Sendemeldung.de sowie über projektbegleitende Angebote der Verbände*/}
      {/*    {' '}*/}
      {/*    <Link color="inherit" href="https://www.privatfunk.de/" target="_blank">APR</Link>*/}
      {/*    {' '}und{' '}*/}
      {/*    <Link color="inherit" href="https://www.vau.net/" target="_blank">VAUNET</Link>*/}
      {/*    {' '}*/}
      {/*    zur Einführung der GEMAGVL-XML-Schnittstelle informiert zu werden, können Sie sich zu unserem*/}
      {/*    Newsletter anmelden.*/}
      {/*    Die von Ihnen bei der Newsletteranmeldung übermittelten Angaben werden von uns gespeichert und ausschließlich*/}
      {/*    für die Zwecke des Newsletterversands verwendet.*/}
      {/*    Die Löschung Ihrer Daten erfolgt automatisiert, sobald Sie sich vom Newsletter explizit abmelden, oder bei*/}
      {/*    Einstellung des projektbegleitenden Newsletter-Services &ndash; je nachdem, was früher eintritt.*/}
      {/*    Wir weisen darauf hin, dass die Kommunikation per E-Mail in der Regel unverschlüsselt erfolgt und daher*/}
      {/*    Sicherheitslücken aufweisen kann.*/}
      {/*    Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte kann somit von uns nicht garantiert werden. Im*/}
      {/*    Übrigen gelten hier auch die Datenschutzbestimmungen Ihres Providers.*/}
      {/*  </Typography>*/}
      {/*</Box>*/}

      <Box my={3}>
        <Typography variant="h6" component="h2" gutterBottom>
          Datensicherheit
        </Typography>

        <Typography variant="body1" gutterBottom>
          Wir treffen technische und organisatorische Sicherheitsvorkehrungen, um Ihre personenbezogenen Daten gegen
          zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter
          Personen
          zu schützen und den Schutz Ihrer Rechte und die Einhaltung der anwendbaren nationalen und europäischen
          Datenschutzvorschriften zu gewährleisten. Unsere Datenverarbeitung und unsere Sicherheitsmaßnahmen werden
          jeweils dem aktuellen Stand der Technik angepasst.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Diese Vorkehrungen sollen die Vertraulichkeit und Integrität Ihrer Daten sowie die Verfügbarkeit und
          Belastbarkeit der Systeme und Dienste bei deren Verarbeitung dauerhaft sicherstellen. Sie sollen außerdem
          die
          Verfügbarkeit der Daten und den Zugang zu ihnen bei einem physischen oder technischen Zwischenfall
          schnellstmöglich wiederherstellen.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Zu unseren Sicherheitsmaßnahmen – vor allem gegen den Zugriff von unbefugten Dritten – gehört auch eine
          Verschlüsselung Ihrer Daten. Alle Informationen, die Sie online auf unserer Webseite eingeben, werden
          verschlüsselt und erst dann übermittelt.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Unsere Mitarbeiter sind im Übrigen zur Vertraulichkeit im Umgang mit Ihren Daten verpflichtet.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" component="h2" gutterBottom>
          Ihre Rechte
        </Typography>

        <Typography variant="body1" gutterBottom>
          Auskunft: Sie haben das Recht, von uns jederzeit Auskunft zu verlangen über die zu Ihnen bei uns
          gespeicherten
          Daten, sowie zu deren Herkunft, Empfängern oder Kategorien von Empfängern, an die diese Daten weitergegeben
          werden und den Zweck der Speicherung (Art. 15 DS-GVO).
        </Typography>

        <Typography variant="body1" gutterBottom>
          Widerspruch: Sie haben das Recht, jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
          bei
          Vorliegen der gesetzlichen Voraussetzungen gegen die Verarbeitung Ihrer Daten Widerspruch einzulegen. Die
          Rechtmäßigkeit der bis zum Widerspruch erfolgten Verarbeitung bleibt dadurch unberührt (Art. 21 DS-GVO).
        </Typography>

        <Typography variant="body1" gutterBottom>
          Berichtigung, Sperrung und Löschung: Sie sind ebenfalls berechtigt eine Berichtigung, Löschung oder Sperrung
          Ihrer personenbezogenen Daten zu verlangen, soweit dem keine gesetzlichen Vorschriften entgegenstehen (Art.
          16, 17 DS-GVO).
        </Typography>

        <Typography variant="body1" gutterBottom>
          Die Ansprüche können jederzeit kostenlos gegenüber uns unter den nachfolgenden Kontaktdaten geltend gemacht
          werden:
        </Typography>

        <Typography variant="body1" gutterBottom>
          SOLE Software GmbH<br/>
          Sommerbergstraße 97<br/>
          66346 Püttlingen<br/>
          Deutschland
        </Typography>

        <Typography variant="body1" gutterBottom>
          Telefon: +49 6806 4999535-0<br/>
          Telefax: +49 6806 4999535-9<br/>
          E-Mail: kontakt@solesoftware.de
        </Typography>

        <Typography variant="body1" gutterBottom>
          Ansprechpartner für Datenschutzfragen ist Prof. Dr. Stephan Ory, E-Mail: <Link
          to="mailto:ory@solesoftware.de">ory@solesoftware.de</Link>.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" component="h2" gutterBottom>
          Beschwerden und zuständige Datenschutzaufsicht
        </Typography>

        <Typography variant="body1" gutterBottom>
          Wenn Sie annehmen, durch unsere Datenverarbeitungsvorgänge in Ihren Rechten verletzt worden zu sein, können
          Sie sich gerne direkt an uns wenden und wir versuchen so schnell wie möglich eine zufriedenstellende Lösung
          zu
          finden.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Sollte Ihnen das im Einzelfall jedoch nicht genügen oder aus Ihrer Sicht zu keinem entsprechenden Ergebnis
          geführt haben, können Sie sich im Wege der Beschwerde an die zuständige Datenschutzaufsichtsbehörde wenden.
          Diese geht der Beschwerde nach und unterrichtet Sie über das Ergebnis. Zuständig ist in diesem Fall:
        </Typography>

        <Typography variant="body1">
          Die Landesbeauftragte für Datenschutz und Informationsfreiheit<br/>
          Monika Grethel<br/>
          Fritz-Dobisch-Str. 12<br/>
          66111 Saarbrücken<br/>
          Deutschland
        </Typography>
      </Box>
    </WelcomeDashboardLayout>
  );
}
